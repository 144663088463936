import React from "react";
import "./Concept.scss"

const Concept = () =>{
    return(
        <div className="Concept">
            <div className="box-1">
                <div className="box-1__desc">
                    <h1>Concept</h1>
                    <p>The keystone in the architecture of our thinking.</p>
                </div>
            </div>
            <div className="box-2"></div>
            <div className="box-3"></div>
            <div className="box-4"></div>
        </div>
    )
}

export default Concept