import React from "react";
import "./Home.scss"
import Navbar from "../../Components/Navbar";
import BannerVid from "../../Assets/Banner.mp4"
import Concept from "./Concept";
import Exhibition from "./Exhibition";

const Home = () =>{
    return(
        <div className="Home">
            <Navbar/>
            <video src={BannerVid} autoPlay loop muted/>
            <Concept/>
            <Exhibition/>
        </div>
    )
}

export default Home