import React from 'react'
import "./Exhibition.scss"

const Exhibition = () =>{
    return(
        <div className="Exhibition">
            <div className="box first"></div>
            <div className="box sec"></div>
            <div className="box third"></div>
            <div className="box fourth"></div>
        </div>
    )
}

export default Exhibition