import React, { useEffect, useState } from "react";
import "./Navbar.scss"

const Navbar = () =>{
    const [navAxis,setNavAxis] = useState(true);
    const changeNav = () =>{
        if(window.scrollY>0){
            setNavAxis(false);
        } 
        else{
            setNavAxis(true);
        }
    }
    console.log(window.scrollY);
    window.addEventListener("scroll",changeNav);
    return(
        <div className="Navbar">
            <div className="imgContain" >
                {/* <img style={navAxis?{}:{height:"50px"}} src="https://interiortoday.in/wp-content/uploads/2015/09/image-1.png" alt="img" /> */}
                <img style={navAxis?{}:{height:"50px"}} src="https://mavonorm-global.com/wp-content/uploads/2021/09/cropped-logo-h-new-9.png" alt="img" />
            </div>
            <ul>
                <li>Home</li>
                <li>Service</li>
                <li>Portfolio</li>
                <li>Trade show</li>
                <li>Company</li>
                <li>Blog</li>
                <li>Contact</li>
            </ul>
        </div>
    )
}

export default Navbar